import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SectionOne from "../Components/SectionOne";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SeeAll = () => {
  let Loader = require("react-loader");

  let options = {
    lines: 13,
    length: 30,
    width: 15,
    radius: 30,
    scale: 1.0,
    corners: 1,
    color: "white",
    opacity: 0.85,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: "50%",
    left: "50%",
    shadow: false,
    hwaccel: false,
    position: "absolute",
  };

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const checkUser = async () => {
    const msisdn = localStorage.getItem("msisdn");
    const password = localStorage.getItem("password");

    console.log({ msisdn, password }, "msisdn and password");

    if (msisdn && password) {
      try {
        console.log("msisdn pass", msisdn, password);
        setLoading(true);
        const url = `https://www.backend.quizboxdemo.visiontrek.in/checkUser`;
        const data = {
          mobileNumber: msisdn,
          password: password,
        };
        const response = await axios.post(url, data);

        setLoading(false);

        setStatus(response.data.response);

        if (response.data.response == "Failed") {
          console.log("api hit and failed response...");
          localStorage.clear();
          navigate("/login");
        }
      } catch (error) {
        console.error("There was an error!", error);
        navigate("/login");
      }
      return;
    } else {
      setLoading(false);
      navigate("/login");
    }
  };
  //Getting On Page Load
  useEffect(() => {
    checkUser();
    getLocalStorageData();
  }, []);

  //State to Store Selected Game Data
  const [data, setData] = useState([]);

  //Getting Data From Local Storage
  const getLocalStorageData = () => {
    let games = JSON.parse(localStorage.getItem("games"));
    // console.log("games ", games);
    setData(games)
;
  };

  return (
    <>
      <div
        className="loading-div"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader loaded={!loading} options={options} className="spinner" />
      </div>
      <Header />
      <SectionOne />
      <div className="area-bg-one">
        <section className="upcoming-games-area upcoming-games-bg pt-120">
          <div className="container">
            <div className="row">
              {data.map((value, index) => {
                return (
                  <div className="col-lg-4 col-md-6" key={index}>
                    <div className="upcoming-game-item mb-40">
                      <div className="upcoming-game-head">
                        <div className="uc-game-head-title">
                          <h4>
                            <span
                              onClick={() => {
                                window.location.href = value.game;
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {value.name}
                            </span>
                          </h4>
                        </div>
                        <div className="uc-game-price">
                          <h5>&#9971;</h5>
                        </div>
                      </div>
                      <p>
                        Compete with players remote island winner takes showdown
                        known issue.
                      </p>
                      <div className="upcoming-game-thumb">
                        <img
                          src={value.image}
                          alt="Src"
                          height="200px"
                          width="200px"
                        />
                        <div className="upcoming-game-cart">
                          <a href={value.game} className="btn transparent-btn">
                            <i className="fas fa-shopping-basket"></i>Play
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className="col-lg-4 col-md-6">
                <div className="upcoming-game-item mb-40">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4>
                        <a href="/">Blocker</a>
                      </h4>
                    </div>
                    <div className="uc-game-price">
                      <h5>&#9971;</h5>
                    </div>
                  </div>
                  <p>
                    Compete with players remote island winner takes showdown
                    known issue.
                  </p>
                  <div className="upcoming-game-thumb">
                    <img src={blocker} alt="" />
                    <div className="upcoming-game-cart">
                      <a href="/" className="btn transparent-btn">
                        <i className="fas fa-shopping-basket"></i>Play
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-lg-4 col-md-6">
                <div className="upcoming-game-item mb-40">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4>
                        <a href="/">Cat and Ghost</a>
                      </h4>
                    </div>
                    <div className="uc-game-price">
                      <h5>&#x1F919;</h5>
                    </div>
                  </div>
                  <p>
                    Compete with players remote island winner takes showdown
                    known issue.
                  </p>
                  <div className="upcoming-game-thumb">
                    <img src={cat_and_ghosts} alt="" />
                    <div className="upcoming-game-cart">
                      <a href="/" className="btn transparent-btn">
                        <i className="fas fa-shopping-basket"></i>Play
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="upcoming-game-item mb-40">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4>
                        <a href="/">Chess Tower</a>
                      </h4>
                    </div>
                    <div className="uc-game-price">
                      <h5>&#x1F93E;</h5>
                    </div>
                  </div>
                  <p>
                    Compete with players remote island winner takes showdown
                    known issue.
                  </p>
                  <div className="upcoming-game-thumb">
                    <img src={chess_tower} alt="" />
                    <div className="upcoming-game-cart">
                      <a href="/" className="btn transparent-btn">
                        <i className="fas fa-shopping-basket"></i>Play
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="upcoming-game-item mb-40">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4>
                        <a href="/">Dice Jump</a>
                      </h4>
                    </div>
                    <div className="uc-game-price">
                      <h5>&#x1F947;</h5>
                    </div>
                  </div>
                  <p>
                    Compete with players remote island winner takes showdown
                    known issue.
                  </p>
                  <div className="upcoming-game-thumb">
                    <img src={dice_jump} alt="" />
                    <div className="upcoming-game-cart">
                      <a href="/" className="btn transparent-btn">
                        <i className="fas fa-shopping-basket"></i>Play
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="upcoming-game-item mb-40">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4>
                        <a href="/">hOOKS</a>
                      </h4>
                    </div>
                    <div className="uc-game-price">
                      <h5>&#x1F3C1;</h5>
                    </div>
                  </div>
                  <p>
                    Compete with players remote island winner takes showdown
                    known issue.
                  </p>
                  <div className="upcoming-game-thumb">
                    <img src={dont_touch_the_hooks} alt="" />
                    <div className="upcoming-game-cart">
                      <a href="/" className="btn transparent-btn">
                        <i className="fas fa-shopping-basket"></i>Play
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="upcoming-game-item mb-40">
                  <div className="upcoming-game-head">
                    <div className="uc-game-head-title">
                      <h4>
                        <a href="/">fREE kICK PeNALITY</a>
                      </h4>
                    </div>
                    <div className="uc-game-price">
                      <h5>&#x1F3B1;</h5>
                    </div>
                  </div>
                  <p>
                    Compete with players remote island winner takes showdown
                    known issue.
                  </p>
                  <div className="upcoming-game-thumb">
                    <img src={Free_Kick_Penalty} alt="" />
                    <div className="upcoming-game-cart">
                      <a href="/" className="btn transparent-btn">
                        <i className="fas fa-shopping-basket"></i>Play
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};
export default SeeAll;