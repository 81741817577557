import React, { useState } from 'react';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [msisdn, setMsisdn] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault(); 
    
        try {
            const url = `https://www.backend.quizboxdemo.visiontrek.in/login/`;
            const data = {
                mobileNumber: msisdn,
                password: password
            };
            const response = await axios.post(url, data);
           
            console.log('response is', response.data.response);
    
            if (response.data.response === "Success") {
                localStorage.setItem('msisdn', msisdn);
                localStorage.setItem('password', password);
                localStorage.setItem('status', 1);
                toast.success('LogIn Success');
                navigate('/');
            } else {
                toast.error('Billing Pending');
            }
    
        } catch (error) {
            console.error('There was an error!', error);
        }
    };
    

    return (
        <div className='bg-[#1F1329] h-screen'>
            <div className='flex justify-center container py-[100px]'>
                <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <p className='text-center text-3xl font-bold text-gray-700'>GAMEVISTA</p>
                        <div>
                            <label htmlFor="msisdn" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile Number</label>
                            <input
                                type="text"
                                id="msisdn"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                required
                                value={msisdn}
                                onChange={(e) => setMsisdn(e.target.value)}
                                placeholder="Enter your Mobile Number"
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter PIN</label>
                            <input
                                type="password"
                                id="password"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your Password"
                            />
                        </div>
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login</button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Login;
